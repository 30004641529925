import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./IconAndTitle.module.css";

export function IconAndTitle({
  as: _Component = _Builtin.Block,
  image = "https://uploads-ssl.webflow.com/6438a86abe16e328b689a629/6438a86abe16e3450d89a69d_pageviews-icon-dashdark-webflow-template.svg",
  text = "Pageviews",
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "flex", "align-center", "gap-column-4px")}
      tag="div"
    >
      <_Builtin.Image
        className={_utils.cx(_styles, "max-h-16px")}
        loading="eager"
        width="auto"
        height="auto"
        src={image}
      />
      <_Builtin.Block
        className={_utils.cx(_styles, "text-100", "medium", "mg-top-2px")}
        tag="div"
      >
        {text}
      </_Builtin.Block>
    </_Component>
  );
}
