import React from "react";
import * as _Builtin from "./_Builtin";
import { IconAndTitle } from "./IconAndTitle";
import { BadgeGreen } from "./BadgeGreen";
import { BadgeRed } from "./BadgeRed";
import * as _utils from "./utils";
import _styles from "./DetailsCard.module.css";

export function DetailsCard({
  as: _Component = _Builtin.Block,
  image = "",
  title = "Details",
  iconVisibility = true,
  amount = "$100",
  badgeGreenText = "25%",
  badgeRedText = "25%",
  amountProps = {},
  badgeGreenVisibility = true,
  badgeRedVisibility = true,
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "card", "top-details")}
      id={_utils.cx(
        _styles,
        "w-node-_9745c905-0e47-203d-ac6e-d1bee1ec357d-e1ec357d"
      )}
      tag="div"
    >
      <_Builtin.Block
        className={_utils.cx(
          _styles,
          "flex-horizontal",
          "justify-space-between"
        )}
        tag="div"
      >
        <IconAndTitle image={image} text={title} />
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "flex", "align-center", "gap-column-6px")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "display-4")}
          tag="div"
          {...amountProps}
        >
          {amount}
        </_Builtin.Block>
        {badgeGreenVisibility ? (
          <_Builtin.Block tag="div">
            <BadgeGreen text={badgeGreenText} dotVisibility={false} />
          </_Builtin.Block>
        ) : null}
        {badgeRedVisibility ? (
          <_Builtin.Block tag="div">
            <BadgeRed text={badgeRedText} dotVisibility={false} />
          </_Builtin.Block>
        ) : null}
      </_Builtin.Block>
    </_Component>
  );
}
