import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./BadgeRed.module.css";

export function BadgeRed({
  as: _Component = _Builtin.Block,
  text = "28.4%",
  dotVisibility = true,
  iconVisibility = true,
  badgeProps = {},
}) {
  return (
    <_Component className={_utils.cx(_styles, "status-badge", "red")} tag="div">
      <_Builtin.Block
        className={_utils.cx(_styles, "flex", "align-center", "gap-column-4px")}
        tag="div"
      >
        {dotVisibility ? (
          <_Builtin.Block
            className={_utils.cx(_styles, "small-dot", "_4px", "bg-red-300")}
            tag="div"
          />
        ) : null}
        <_Builtin.Block
          className={_utils.cx(_styles, "paragraph-small")}
          tag="div"
          {...badgeProps}
        >
          {text}
        </_Builtin.Block>
      </_Builtin.Block>
      {iconVisibility ? (
        <_Builtin.Block
          className={_utils.cx(
            _styles,
            "dashdark-custom-icon",
            "icon-size-9px"
          )}
          tag="div"
        >
          {""}
        </_Builtin.Block>
      ) : null}
    </_Component>
  );
}
