import * as React from "react";
/*
 * The cx function takes a css module object and the name of the element classes.
 * It will extract the final class names from the object if the class doesn't exist
 * on the object it will use the original name
 * */
export const cx = (style, ...classNames) => cj(...classNames.map((c) => style[c] ?? c));
/**
 * The cj function takes care of eliminting classes that don't exist and joining
 * them with a white space.
 */
export const cj = (...classNames) => classNames.filter(Boolean).join(" ");
const UNESCAPED_CHARS = /(\\b|\\f|\\r\\n|\\n|\\r|\\t|\\v)/gm;
export const removeUnescaped = (value) => decodeURIComponent(value).replace(UNESCAPED_CHARS, "");
export const replaceSelector = (selector, styles) => {
    return selector
        .split(".")
        .map((c) => styles?.[c] ?? c)
        .join(".");
};
export function debounce(func, timeout = 0) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}
/**
 * cubic bezier functions have been extracted from this repository:
 * https://github.com/ai/easings.net
 */
export const EASING_FUNCTIONS = {
    linear: "linear",
    ease: "ease",
    "ease-in": "ease-in",
    "ease-out": "ease--out",
    "ease-in-out": "ease-in-out",
    "ease-in-sine": "cubic-bezier(0.12, 0, 0.39, 0)",
    "ease-out-sine": "cubic-bezier(0.61, 1, 0.88, 1)",
    "ease-in-out-sine": "cubic-bezier(0.37, 0, 0.63, 1)",
    "ease-in-quad": "cubic-bezier(0.11, 0, 0.5, 0)",
    "ease-out-quad": "cubic-bezier(0.5, 1, 0.89, 1)",
    "ease-in-out-quad": "cubic-bezier(0.45, 0, 0.55, 1)",
    "ease-in-cubic": "cubic-bezier(0.32, 0, 0.67, 0)",
    "ease-out-cubic": "cubic-bezier(0.33, 1, 0.68, 1)",
    "ease-in-out-cubic": "cubic-bezier(0.65, 0, 0.35, 1)",
    "ease-in-quart": "cubic-bezier(0.5, 0, 0.75, 0)",
    "ease-out-quart": "cubic-bezier(0.25, 1, 0.5, 1)",
    "ease-in-out-quart": "cubic-bezier(0.76, 0, 0.24, 1)",
    "ease-in-quint": "cubic-bezier(0.64, 0, 0.78, 0)",
    "ease-out-quint": "cubic-bezier(0.22, 1, 0.36, 1)",
    "ease-in-out-quint": "cubic-bezier(0.83, 0, 0.17, 1)",
    "ease-in-expo": "cubic-bezier(0.7, 0, 0.84, 0)",
    "ease-out-expo": "cubic-bezier(0.16, 1, 0.3, 1)",
    "ease-in-out-expo": "cubic-bezier(0.87, 0, 0.13, 1)",
    "ease-in-circ": "cubic-bezier(0.55, 0, 1, 0.45)",
    "ease-out-circ": "cubic-bezier(0, 0.55, 0.45, 1)",
    "ease-in-out-circ": "cubic-bezier(0.85, 0, 0.15, 1)",
    "ease-in-back": "cubic-bezier(0.36, 0, 0.66, -0.56)",
    "ease-out-back": "cubic-bezier(0.34, 1.56, 0.64, 1)",
    "ease-in-out-back": "cubic-bezier(0.68, -0.6, 0.32, 1.6)",
};
export const isServer = typeof window === "undefined";
export const useLayoutEffect = (isServer ? () => { } : React.useLayoutEffect);
export function useResizeObserver(ref, fn) {
    const observer = React.useMemo(() => (isServer ? null : new ResizeObserver(([entry]) => fn(entry))), [fn]);
    React.useEffect(() => {
        const target = ref.current;
        if (!target)
            return;
        observer?.observe(ref.current);
        return () => observer?.unobserve(target);
    }, [ref, observer]);
}
