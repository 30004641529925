import React, { useState, useEffect } from 'react';
import * as _Builtin from "./_Builtin";
import { DetailsCard } from "./DetailsCard";
import * as _utils from "./utils";
import _styles from "./FinancialMetricsGrid.module.css";
import total_profit from "./../../images/total_profit.svg";
import company_list from "./../../data/company_list.json";

export function FinancialMetricsGrid({
    as: _Component = _Builtin.Block
    , ticker = "ticker"
}) {

    const [financialMetrics, setFinancialMetrics] = useState([]);
    const [isFinancialMetrics, setIsFinancialMetrics] = useState(false);

    const [marketCapData, setMarketCapData] = useState([]);
    const [isMarketCapData, setIsMarketCapData] = useState(false);

    const [stockPeersData, setStockPeersData] = useState([]);
    const [isStockPeersData, setIsStockPeersData] = useState(false);

    const [peerList, setPeerList] = useState([]);
    const [isPeerList , setIsPeerList] = useState(false);

    useEffect(() => {
        // declare the data fetching function
        const fetchData = async () => {
            const incomeStatement = await fetch(`/api/income-statement?companyTicker=${ticker}`);
            const incomeStatementJSON = await incomeStatement.json();
            setFinancialMetrics(incomeStatementJSON);
            setIsFinancialMetrics(true);

            const marketCap = await fetch(`/api/market-cap?companyTicker=${ticker}`);
            const jsonMarketCap = await marketCap.json();
            setMarketCapData(jsonMarketCap);
            setIsMarketCapData(true);

            const stockPeers = await fetch(`/api/stock-peers?companyTicker=${ticker}`);
            const jsonStockPeers = await stockPeers.json();
            setStockPeersData(jsonStockPeers[0]['peersList']);
            setIsStockPeersData(true);
            
            const company_names = [];
            jsonStockPeers[0]['peersList'].forEach(function(ticker) {
                var result = company_list.find(function(obj) {
                    return obj.symbol === ticker;
                });
                if (result) {
                    company_names.push(result.name);
                  }
            })
            setPeerList(company_names);
            setIsPeerList(true);
        }

        fetchData()
            // make sure to catch any error
            .catch(console.error);

    }, [ticker])

    const metrics = ['revenue', 'costOfRevenue', 'grossProfit', 'operatingIncome', 'operatingExpenses', 'sellingGeneralAndAdministrativeExpenses']

    return (
        <_Component
            className={_utils.cx(_styles, "small-details-card-grid")}
            tag="div"
        >
            {isFinancialMetrics && metrics.map((metric, index) => (
                <DetailsCard
                    image={total_profit}
                    title={metric
                        .replace(/([A-Z])/g, ' $1')
                        // uppercase the first character
                        .replace(/^./, function (str) { return str.toUpperCase(); })}
                    amount={'$' +
                        Intl.NumberFormat('en-US', {
                            notation: "compact",
                            maximumFractionDigits: 2
                        }).format(Number(financialMetrics[0][`${metric}`]))
                    }
                    badgeRedText={"30%"}
                    badgeGreenText={"30%"}
                    badgeGreenVisibility={false}
                    badgeRedVisibility={false}
                    key={index} />
            ))}

            {isMarketCapData &&
                <DetailsCard
                    image={total_profit}
                    title={'Market Capitalization as of ' + marketCapData[0]['date']
                        .replace(/([A-Z])/g, ' $1')
                        // uppercase the first character
                        .replace(/^./, function (str) { return str.toUpperCase(); })}
                    amount={'$' +
                        Intl.NumberFormat('en-US', {
                            notation: "compact",
                            maximumFractionDigits: 2
                        }).format(Number(marketCapData[0]['marketCap']))
                    }
                    badgeRedText={"30%"}
                    badgeGreenText={"30%"}
                    badgeGreenVisibility={false}
                    badgeRedVisibility={false}
                />
            }
            {isStockPeersData && isPeerList &&
                <DetailsCard
                    image={total_profit}
                    title={'Stock Peers'
                        .replace(/([A-Z])/g, ' $1')
                        // uppercase the first character
                        .replace(/^./, function (str) { return str.toUpperCase(); })}
                    amount={peerList.join(', ')}
                    badgeRedText={"30%"}
                    badgeGreenText={"30%"}
                    badgeGreenVisibility={false}
                    badgeRedVisibility={false}
                />
            
            } 
        </_Component>
    );
}
