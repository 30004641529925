import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Section from './Section';
import SectionHeader from './SectionHeader';
import CompanyDashboard from './CompanyDashboard.tsx';
import { useAuth } from '../util/auth';

function ResearchSection(props) {

    const auth = useAuth();

    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container>
                <SectionHeader
                    title={props.title}
                    subtitle={props.subtitle}
                    size={4}
                    textAlign="center"
                />

                {auth.user.planIsActive && (
                    <>
                        <CompanyDashboard />
                    </>
                )}

                {!auth.user.planIsActive && (
                    <Box mx="auto" mb={4} maxWidth={400}>
                        <Alert severity="error">
                            You are not subscribed to a paid plan
                            <span
                                role="img"
                                aria-label="party"
                                style={{ marginLeft: "10px" }}
                            >
                            </span>
                        </Alert>
                    </Box>
                )}

            </Container>
        </Section>
    );
}

export default ResearchSection;