import React, { useState } from 'react';
import InputField from './InputField';
import { useForm } from "react-hook-form";
import company_list from '../data/company_list.json';
import Fuse from 'fuse.js'
import { requireAuth, useAuth } from "../util/auth";
import SearchBar from './SearchBar';
import { Dashboard } from './devlink/Dashboard';

function CompanyDashboard() {

    // const Fuse = require('fuse.js/dist/fuse.common')

    // const options = {
    //     keys: [
    //         "symbol",
    //         "name"
    //     ]
    // };

    // interface Company {
    //     symbol: string;
    //     name: string;
    //     price: number;
    //     exchange: string;
    //     exchangeShortName: string;
    //     type: string;
    // };

    // const fuse = new Fuse(company_list, options);
    
    const auth = useAuth();

    const { register } = useForm();

    const [ticker, setUpdatedTicker] = useState('');
    const [isTicker, setIsTicker] = useState(false);

    const [companyName, setCompanyName] = useState('');
    const [companyDescription, setCompanyDescription] = useState('');
    const [isCompanyDetails, setIsCompanyDetails] = useState(false);

    const [companyIndustry, setCompanyIndustry] = useState('');
    const [companySells, setCompanySells] = useState('');
    const [companyServes, setCompanyServes] = useState('');
    const [companyEndUsers, setCompanyEndUsers] = useState('');
    const [isCompanySummaryHighlights, setIsCompanySummaryHighlights] = useState(false);

    const [filingLink, setFilingLink] = useState('');
    const [isFilingLink, setIsFilingLink] = useState(false);

    const updateCompanyName = (companyName: string): (void) => {
        setCompanyName(companyName)
    }

    const updateCompanyDescription = (companyDescription: string): (void) => {
        setCompanyDescription(companyDescription)
    }

    const updateCompanyIndustry = (companyIndustry: string): (void) => {
        setCompanyIndustry(companyIndustry)
    }

    const updateCompanySells = (companySells: string): (void) => {
        setCompanySells(companySells)
    }

    const updateCompanyServes = (companyServes: string): (void) => {
        setCompanyServes(companyServes)
    }

    const updateCompanyEndUsers = (companyEndUsers: string): (void) => {
        setCompanyEndUsers(companyEndUsers)
    }

    const updateFilingLink = (filingLink: string): (void) => {
        setFilingLink(filingLink)
    }

    const fetchData = async (tckr: String) => {
        // get the data from the api
        const data = await fetch(`/api/filings?companyTicker=${tckr}`);
        // convert the data to json
        const json = await data.json();
        // console.log(json);
        // get the filing link
        updateFilingLink(json[0]['finalLink'])
        setIsFilingLink(true)
    }

    const fetchCompanyData = async (tckr: String) => {
        // get the data from the api
        const temp = await fetch(`/api/company-info?companyTicker=${tckr}`);

        // convert the data to json
        const jsonCompany = await temp.json();
        const tempCompanyName = jsonCompany[0]['companyName']
        const tempCompanyDescription = jsonCompany[0]['description']

        // get the filing link
        updateCompanyName(tempCompanyName)
        updateCompanyDescription(tempCompanyDescription)
        setIsCompanyDetails(true)
        fetchCompanySummary(tempCompanyName, tempCompanyDescription)
    }

    const fetchCompanySummary = async (company_name: String, company_description: String) => {
        // get the data from the api
        const api_call = await fetch(`/api/company-summary-highlights?companyName=${company_name}?companyDescription=${company_description}`);

        // convert the data to json
        const json = await api_call.json();

        // Extract the Copmany Summary Highlights
        updateCompanyIndustry(json['company_summary']['company_industry'])
        updateCompanySells(json['company_summary']['company_sells'])
        updateCompanyServes(json['company_summary']['company_serves'])
        updateCompanyEndUsers(json['company_summary']['company_end_users'])
        setIsCompanySummaryHighlights(true)
    }

    const handleSubmit = (event: any): (void) => {
        event.preventDefault();

        const tickerSubmitted = event.target[0].value;
        const tickerLength = (tickerSubmitted as string).length;

        // Check if they ticker is the same as the last ticker or if it's empty. If not equal or of size 0 then set all the variables as false and rerun the backend calls
        if (ticker !== tickerSubmitted || tickerLength === 0) {
            setIsTicker(false)
            setIsFilingLink(false)
            setIsCompanyDetails(false)
            setIsCompanySummaryHighlights(false)
        }

        // This portion is to check if the ticker is empty. If it's empty then clear the variables
        if (tickerLength !== 0) {
            setIsTicker(true);
            updateTicker(tickerSubmitted);
            fetchData(tickerSubmitted);
            fetchCompanyData(tickerSubmitted);
        }

    };

    const updateTicker = (str: any): (void) => {
        setUpdatedTicker(str);
    };

    return (
        <>
            <InputField placeholder="Enter Company Ticker" handleSubmit={handleSubmit} />
            {/* <SearchBar bgColor="default" size="medium" bgImage="" bgImageOpacity={1} /> */}
            {isTicker && isFilingLink && isCompanyDetails && isCompanySummaryHighlights &&
                <>
                    <Dashboard
                        name={auth.user.name}
                        ticker={ticker}
                        filingLink={filingLink}
                        companyName={companyName}
                        companyDescription={companyDescription}
                        companySummary={{industry: companyIndustry, sells: companySells, serves: companyServes, endUsers: companyEndUsers}}
                    />
                </>
            }
        </>
    );
}

export default requireAuth(CompanyDashboard);