import * as React from "react";
import * as utils from "../utils";
export function Block({ tag = "div", ...props }) {
    return React.createElement(tag, props);
}
export function Span(props) {
    return React.createElement("span", { ...props });
}
export function Blockquote(props) {
    return React.createElement("blockquote", { ...props });
}
export function Emphasized(props) {
    return React.createElement("em", { ...props });
}
export function Figure({ className = "", figure, ...props }) {
    const { type, align } = figure;
    if (align) {
        className += `w-richtext-align-${align} `;
    }
    if (type) {
        className += `w-richtext-align-${type} `;
    }
    return React.createElement("figure", { className: className, ...props });
}
export function Figcaption(props) {
    return React.createElement("figcaption", { ...props });
}
export function Subscript(props) {
    return React.createElement("sub", { ...props });
}
export function Superscript(props) {
    return React.createElement("sup", { ...props });
}
export function Heading({ tag = "h1", ...props }) {
    return React.createElement(tag, props);
}
export const Link = React.forwardRef(function Link({ options = { href: "#" }, className = "", button = false, ...props }, ref) {
    if (button)
        className += " w-button";
    const { href, target, preload = "none" } = options;
    const shouldRenderResource = preload !== "none" && typeof href === "string" && !href.startsWith("#");
    return (React.createElement(React.Fragment, null,
        React.createElement("a", { href: href, target: target, className: className, ref: ref, ...props }),
        shouldRenderResource && React.createElement("link", { rel: preload, href: href })));
});
export function Strong(props) {
    return React.createElement("strong", props);
}
export function FormWrapper({ className = "", state: initialState = "normal", onSubmit, children, ...props }) {
    const [state, setState] = React.useState(initialState);
    return React.createElement("div", {
        className: className + " w-form",
        ...props,
    }, React.Children.map(children, (child) => {
        if (child.type === FormForm) {
            return React.cloneElement(child, {
                ...child.props,
                // @ts-ignore
                onSubmit: (e) => {
                    try {
                        // @ts-ignore
                        onSubmit(e);
                        setState("success");
                    }
                    catch (err) {
                        /**
                         * Capture the error to correctly set the state, but rethrow it
                         * in case another error handling method is used above up in
                         * the tree (e.g. Error Boundaries)
                         */
                        setState("error");
                        throw err;
                    }
                },
                style: {
                    display: state == "normal" || state == "error" ? "block" : "none",
                },
            });
        }
        if (child.type === FormSuccessMessage) {
            return React.cloneElement(child, {
                ...child.props,
                // @ts-ignore
                style: { display: state == "success" ? "block" : "none" },
            });
        }
        if (child.type === FormErrorMessage) {
            return React.cloneElement(child, {
                ...child.props,
                // @ts-ignore
                style: { display: state == "error" ? "block" : "none" },
            });
        }
        return child;
    }));
}
export function FormForm(props) {
    return React.createElement("form", props);
}
export function FormBlockLabel(props) {
    return React.createElement("label", props);
}
export function FormTextInput({ className = "", ...props }) {
    return React.createElement("input", {
        type: "text",
        className: className + " w-input",
        ...props,
    });
}
export function FormTextarea({ className = "", ...props }) {
    return React.createElement("input", {
        type: "textarea",
        className: className + " w-input",
        ...props,
    });
}
export function FormInlineLabel({ className = "", ...props }) {
    return React.createElement("span", {
        className: className + " w-form-label",
        ...props,
    });
}
export function FormCheckboxWrapper({ className = "", ...props }) {
    return React.createElement("label", {
        className: className + " w-checkbox",
        ...props,
    });
}
export function FormRadioWrapper({ className = "", ...props }) {
    return React.createElement("label", {
        className: className + " w-radio",
        ...props,
    });
}
export function FormCheckboxInput({ className = "", checked = false, ...props }) {
    const checkedProps = {};
    if (typeof checked === "boolean") {
        checkedProps["defaultChecked"] = checked;
    }
    return React.createElement("input", {
        type: "checkbox",
        className: className + " w-checkbox",
        ...checkedProps,
        ...props,
    });
}
export function FormRadioInput({ className = "", inputType, ...props }) {
    if (inputType === "custom") {
        // TODO: support FormCustomRadioInput
    }
    return React.createElement("input", {
        className: className + " w-radio-input",
        ...props,
    });
}
const MAX_FILE_SIZE_DEFAULT = 10485760;
const FileUploadContext = React.createContext({
    files: null,
    error: null,
    maxSize: MAX_FILE_SIZE_DEFAULT,
    setFiles: () => { },
    setError: () => { },
});
export function FormFileUploadWrapper({ maxSize = MAX_FILE_SIZE_DEFAULT, ...props }) {
    const [files, setFiles] = React.useState(null);
    const [error, setError] = React.useState(null);
    return React.createElement(FileUploadContext.Provider, {
        value: { files, setFiles, error, setError, maxSize },
    }, React.createElement(_FormFileUploadWrapper, { ...props }));
}
export function _FormFileUploadWrapper({ className = "", ...props }) {
    return React.createElement("div", {
        className: className + " w-file-upload",
        ...props,
    });
}
export function FormFileUploadDefault({ className = "", ...props }) {
    const { files, error } = React.useContext(FileUploadContext);
    return React.createElement("div", {
        className: className + " w-file-upload-default",
        ...props,
        style: {
            ...props.style,
            display: !files || error ? "block" : "none",
        },
    });
}
export function FormFileUploadInput({ className = "", ...props }) {
    const { setFiles, setError, maxSize } = React.useContext(FileUploadContext);
    return React.createElement("input", {
        type: "file",
        className: className + " w-file-upload-input",
        ...props,
        onChange: (e) => {
            if (e.target.files) {
                if (e.target.files[0].size <= maxSize) {
                    setError(null);
                    setFiles(e.target.files);
                }
                else
                    setError("SIZE_ERROR");
            }
        },
    });
}
export function FormFileUploadLabel({ className = "", ...props }) {
    return React.createElement("label", {
        className: className + " w-file-upload-label",
        ...props,
    });
}
export function FormFileUploadText({ className = "", ...props }) {
    return React.createElement("div", {
        className: className + " w-inline-block",
        ...props,
    });
}
export function FormFileUploadInfo({ className = "", ...props }) {
    return React.createElement("div", {
        className: className + " w-file-upload-info",
        ...props,
    });
}
export function FormFileUploadUploading({ className = "", ...props }) {
    return React.createElement("div", {
        className: className + " w-file-upload-uploading",
        style: { ...props.style, display: "none" },
        ...props,
    });
}
export function FormFileUploadUploadingBtn({ className = "", ...props }) {
    return React.createElement("div", {
        className: className + " w-file-upload-uploading-btn",
        ...props,
    });
}
export function FormFileUploadUploadingIcon({ className = "", ...props }) {
    return React.createElement("svg", {
        className: className + " icon w-icon-file-upload-uploading",
        ...props,
    }, React.createElement(React.Fragment, null,
        React.createElement("path", { fill: "currentColor", opacity: ".2", d: "M15 30a15 15 0 1 1 0-30 15 15 0 0 1 0 30zm0-3a12 12 0 1 0 0-24 12 12 0 0 0 0 24z" }),
        React.createElement("path", { fill: "currentColor", opacity: ".75", d: "M0 15A15 15 0 0 1 15 0v3A12 12 0 0 0 3 15H0z" },
            React.createElement("animateTransform", { attributeName: "transform", attributeType: "XML", dur: "0.6s", from: "0 15 15", repeatCount: "indefinite", to: "360 15 15", type: "rotate" }))));
}
export function FormFileUploadSuccess({ className = "", ...props }) {
    const { files, error } = React.useContext(FileUploadContext);
    return React.createElement("div", {
        className: className + " w-file-upload-success",
        ...props,
        style: {
            ...props.style,
            display: !!files && !error ? "block" : "none",
        },
    });
}
export function FormFileUploadFile({ className = "", ...props }) {
    return React.createElement("div", {
        className: className + " w-file-upload-file",
        ...props,
    });
}
export function FormFileUploadFileName({ className = "", ...props }) {
    const { files } = React.useContext(FileUploadContext);
    return React.createElement("div", {
        className: className + " w-file-upload-file-name",
        ...props,
    }, files && files[0].name);
}
export function FormFileUploadRemoveLink({ className = "", ...props }) {
    const { setFiles } = React.useContext(FileUploadContext);
    return React.createElement("div", {
        className: className + " w-file-remove-link",
        ...props,
        onClick: () => {
            setFiles(null);
        },
    });
}
export function FormFileUploadError({ className = "", ...props }) {
    const { error } = React.useContext(FileUploadContext);
    return React.createElement("div", {
        className: className + " w-file-upload-error",
        ...props,
        style: {
            ...props.style,
            display: !!error ? "block" : "none",
        },
    });
}
export function FormFileUploadErrorMsg({ errors, className = "", ...props }) {
    const { error } = React.useContext(FileUploadContext);
    return React.createElement("div", {
        className: className + " w-file-upload-error-msg",
        ...props,
    }, errors[error ?? "GENERIC_ERROR"]);
}
export function FormButton({ className = "", value, ...props }) {
    return React.createElement("input", {
        type: "submit",
        value: value ?? "",
        className: className + " w-button",
        ...props,
    });
}
export function SearchForm(props) {
    return React.createElement("form", props);
}
export function SearchInput({ className = "", ...props }) {
    return React.createElement("input", {
        type: "text",
        className: className + " w-input",
        ...props,
    });
}
export function SearchButton({ value = "", className = "", ...props }) {
    return React.createElement("input", {
        type: "submit",
        value,
        className: className + " w-button",
        ...props,
    });
}
export function FormSuccessMessage({ className = "", ...props }) {
    return React.createElement("div", {
        className: className + " w-form-done",
        ...props,
    });
}
export function FormErrorMessage({ className = "", ...props }) {
    return React.createElement("div", {
        className: className + " w-form-fail",
        ...props,
    });
}
function hasValue(str) {
    if (typeof str !== "string")
        return false;
    //   is &nbsp
    return str.replace(/^[s ]+|[s ]+$/g, "").length > 0;
}
export function FormSelect({ options, className = "", ...props }) {
    return React.createElement("select", { className: className + " w-select", ...props }, options.map(({ v, t }, index) => React.createElement("option", { key: index, value: hasValue(v) ? v : "" }, hasValue(t) ? t : "")));
}
export function List({ tag = "ul", list, className = "", ...props }) {
    return React.createElement(tag, {
        role: "list",
        className: className + (list.unstyled ? " w-list-unstyled" : ""),
        ...props,
    });
}
export function ListItem(props) {
    return React.createElement("li", props);
}
export function Paragraph(props) {
    return React.createElement("p", props);
}
export function Image(props) {
    return React.createElement("img", props);
}
export function Section({ tag = "section", ...props }) {
    return React.createElement(tag, props);
}
export const Container = React.forwardRef(function Container({ tag = "div", className = "", ...props }, ref) {
    return React.createElement(tag, {
        className: className + " w-container",
        ref,
        ...props,
    });
});
export function HtmlEmbed({ tag = "div", className = "", value, ...props }) {
    return React.createElement(tag, {
        className: className + " w-embed",
        dangerouslySetInnerHTML: { __html: utils.removeUnescaped(value) },
        ...props,
    });
}
export function Grid({ tag = "div", className = "", ...props }) {
    return React.createElement(tag, {
        className: className + " w-layout-grid",
        ...props,
    });
}
export function Icon({ widget, className = "", ...props }) {
    return React.createElement("div", {
        className: className + ` w-icon-${widget.icon}`,
        ...props,
    });
}
export function Column({ tag = "div", className = "", columnClasses = "", grid: _, ...props }) {
    return React.createElement(tag, {
        className: className + " w-col " + columnClasses,
        ...props,
    });
}
const transformWidths = (width, index) => {
    const widths = width?.split("|") ?? [];
    return widths.length > 1 ? widths[index] : width;
};
const columnClass = (width, key) => {
    if (/stack$/.test(width))
        return "w-col-stack";
    if (/main$/.test(key))
        return `w-col-${width}`;
    return `w-col-${key}-${width}`;
};
export function Row({ tag = "div", className = "", grid, children, ...props }) {
    return React.createElement(tag, { className: className + " w-row", ...props }, grid
        ? React.Children.map(children, (child, index) => {
            if (child && typeof child === "object" && child.type !== Column)
                return child;
            const columnClasses = Object.entries(grid.cols ?? {}).reduce((acc, [key, value]) => {
                const width = transformWidths(value === "custom" ? "6|6" : value, index);
                acc.add(width ? columnClass(width, key) : "");
                return acc;
            }, new Set());
            return React.cloneElement(child, {
                // @ts-ignore
                columnClasses: [...columnClasses].join(" "),
                ...child.props,
            });
        })
        : children);
}
export function RichText({ tag = "div", className = "", ...props }) {
    return React.createElement(tag, {
        className: className + " w-richtext",
        ...props,
    });
}
export function NotSupported({ _atom = "" }) {
    return React.createElement("div", null, `This builtin is not currently supported: ${_atom}`);
}
