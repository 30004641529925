import React from "react";
import { useEffect, useState } from 'react';
import * as _Builtin from "./_Builtin";
import { RiskFactorComponent } from "./RiskFactorComponent";
import * as _utils from "./utils";
import _styles from "./RiskFactorsSection.module.css";

export function DiscoveryQuestions({
    as: _Component = _Builtin.Block
    , companyName = "Name of the Company"
    , filingLink = "Filing Link"
}) {

    const [data, setData] = useState([]);

    useEffect(() => {
        // declare the data fetching function
        const fetchData = async () => {
            // get the data from the api
            const data = await fetch(`../../../api/discovery-questions?companyName=${companyName}?filingLink=${filingLink}`);
            // convert the data to json
            const questions = await data.json();
            // set state with the result
            setData(questions.discovery_questions);
        }

        fetchData()
            // make sure to catch any error
            .catch(console.error);

    }, [companyName, filingLink])

    let count = 1;

    return (
        <_Component className={_utils.cx(_styles, "mg-bottom-16px")} tag="div">
            <_Builtin.Block className={_utils.cx(_styles, "card")} tag="div">
                <_Builtin.Heading className={_utils.cx(_styles, "heading-2")} tag="h2">
                    {"Discovery Questions"}
                </_Builtin.Heading>
                {data.map((discoveryQuestion, index) => (
                    <RiskFactorComponent
                        riskFactorName={'Question ' + count++}
                        riskFactorDescription={discoveryQuestion.question_title}
                        key={index} />
                ))}
            </_Builtin.Block>
        </_Component>
    );
}
