import React from 'react';
import './InputField.css';

/**
 *  - ticker -> which will be of type string
 *  - onTickerChange -> this will handle the onChange events anytime a user changes the input in the field
 *  - placeholder -> this will be the helper text that is inside the input field before users manually type in a ticker
 */
type Props = {
    placeholder: string;
    handleSubmit: (event: React.FormEvent) => void;
};

function InputField({ placeholder, handleSubmit }: Props) {

    return (
        <form className="input" onSubmit={handleSubmit}>
            <input
                type="input"
                name="ticker"
                defaultValue=""
                placeholder={placeholder}
                className="input__box">
            </input>
            <button className="input__submit" type="submit">
                Go
            </button>
        </form>
    )
}

export default InputField;