import React from "react";
import Meta from "./../components/Meta";
import ResearchSection from "./../components/ResearchSection";
import { requireAuth } from "./../util/auth";

function SearchPage(props) {
    return (
        <>
            <Meta title="Search" />
            <ResearchSection
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                title="Search"
                subtitle=""
            />
        </>
    );
}

export default requireAuth(SearchPage);
