import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import SearchPage from "./search";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import Chat from "./../components/Chat";
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import logoSVG from './../images/banner_logo.svg'; // relative path to image 

function App(props) {
    return (
        <QueryClientProvider>
            <ThemeProvider>
                <AuthProvider>
                    <Chat />
                    <Router>
                        <>
                            <Navbar
                                color="default"
                                logo={logoSVG}
                                logoInverted={logoSVG}
                            />

                            <Switch>
                                {/* <Route exact path="/" component={IndexPage} /> */}

                                {/* <Route exact path="/about" component={AboutPage} /> */}

                                {/* <Route exact path="/faq" component={FaqPage} /> */}

                                {/* <Route exact path="/contact" component={ContactPage} /> */}

                                <Route exact path="/" component={SearchPage} />

                                <Route exact path="/pricing" component={PricingPage} />

                                <Route exact path="/search" component={SearchPage} />

                                <Route exact path="/auth/:type" component={AuthPage} />

                                <Route exact path="/settings/:section" component={SettingsPage} />

                                <Route exact path="/legal/:section" component={LegalPage} />

                                <Route exact path="/purchase/:plan" component={PurchasePage} />

                                <Route
                                    exact
                                    path="/firebase-action"
                                    component={FirebaseActionPage}
                                />

                                <Route component={NotFoundPage} />
                            </Switch>

                            <Footer
                                bgColor="default"
                                size="normal"
                                bgImage=""
                                bgImageOpacity={1}
                                description="The ultimate sales intelligence tool for data-driven decision-making."
                                copyright={`© ${new Date().getFullYear()} Company`}
                                logo={logoSVG}
                                logoInverted={logoSVG}
                                sticky={true}
                            />
                        </>
                    </Router>
                </AuthProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
